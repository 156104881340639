// extracted by mini-css-extract-plugin
export var address = "Footer-module--address--E-atg";
export var addressAndSocials = "Footer-module--address-and-socials--RZhNG";
export var bottomFooter = "Footer-module--bottom-footer--t+qil";
export var contactsWrapper = "Footer-module--contacts-wrapper--z3AZs";
export var divider = "Footer-module--divider--9vM7V";
export var footerContainer = "Footer-module--footer-container--cT4dY";
export var footerItem = "Footer-module--footer-item--CNCki";
export var footerLogo = "Footer-module--footer-logo--yl1X2";
export var footerNav = "Footer-module--footer-nav--UhFCv";
export var footerSubItem = "Footer-module--footer-sub-item--DOdcN";
export var gatsbyFocusWrapper = "Footer-module--gatsby-focus-wrapper--0r7rD";
export var hideOnDesktop = "Footer-module--hide-on-desktop--CSLlK";
export var hideOnMobile = "Footer-module--hide-on-mobile--7A-ML";
export var iconCar = "Footer-module--icon-car--EO7Tu";
export var iconCars = "Footer-module--icon-cars--7Fd1P";
export var iconClock = "Footer-module--icon-clock--pjm34";
export var iconEletricCar = "Footer-module--icon-eletric-car--zj4Mb";
export var iconFacebook = "Footer-module--icon-facebook--1bwp2";
export var iconGear = "Footer-module--icon-gear--ABAmL";
export var iconInstagram = "Footer-module--icon-instagram--88QYH";
export var iconKm = "Footer-module--icon-km--iNT9e";
export var iconPhone = "Footer-module--icon-phone--3wEXd";
export var iconRight = "Footer-module--icon-right--bu+Ng";
export var iconWhatsapp = "Footer-module--icon-whatsapp--eAncW";
export var pageWidth = "Footer-module--page-width--hWXej";
export var path1 = "Footer-module--path1--knlAD";
export var path2 = "Footer-module--path2--FlPpN";
export var path3 = "Footer-module--path3--YJksY";
export var phone = "Footer-module--phone--rnW07";
export var socials = "Footer-module--socials--pWcv+";
export var subItemList = "Footer-module--sub-item-list--gP4du";
export var tiki = "Footer-module--tiki--vCzQo";
export var upperFooter = "Footer-module--upper-footer--9FpvO";
export var view = "Footer-module--view--4Nalc";
export var views = "Footer-module--views--W7VXr";
export var whats = "Footer-module--whats--ulsIy";