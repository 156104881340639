import React from 'react'

import * as st from '~/assets/styl/Footer.module.styl'
import { footerItems } from '~/data'
import { instagram, facebook } from '~/utils/socials.js'

import dimasLogo from '~/assets/img/dimas-logo.svg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { LazyImage } from 'react-lazy-images'

export default function Footer() {
  return (
    <footer>
      <div className={st.footerContainer}>
        <div className={st.upperFooter}>
          <LazyImage
            src={dimasLogo}
            className={st.footerLogo}
            placeholder={({ ref }) => <img ref={ref} src={dimasLogo} />}
            actual={({ imageProps }) => <img {...imageProps} />}
          />
          <nav>
            <ul className={st.footerNav}>
              {footerItems.map((item, key) => (
                <li key={key}>
                  <AnchorLink className={st.footerItem} to={item.slug}>
                    {item.name}
                  </AnchorLink>
                  {item.subItems && (
                    <ul className={st.subItemList}>
                      {item.subItems.map((subItem, key) => (
                        <li key={key}>
                          {(subItem.external && (
                            <a
                              className={st.footerSubItem}
                              target="__blank"
                              href={subItem.slug}
                            >
                              {subItem.name}
                            </a>
                          )) || (
                            <AnchorLink
                              className={st.footerSubItem}
                              to={subItem.slug}
                            >
                              {subItem.name}
                            </AnchorLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className={st.divider}></div>
        <div className={st.bottomFooter}>
          <section>
            <h2>Atendimento Dimas Sempre</h2>
            <div className={st.contactsWrapper}>
              <div className={st.phone}>
                <span className="icon-phone"></span>
                (48) 3113-2022
              </div>
              <div className={st.whats}>
                <a
                  href="https://wa.me/+5548984036797"
                  target="_blank"
                  rel="noreferrer external nofollow"
                >
                  <span className="icon-whatsapp"></span>
                  Chame no WhatsApp
                </a>
              </div>
            </div>
          </section>
          <div className={st.addressAndSocials}>
            <address>
              Av. Marinheiro Max Schramm nº 2700
              <br />
              Estreito, Florianópolis - CEP 88095-000
            </address>
            <div className={st.socials}>
              <a
                href={facebook}
                target="_blank"
                rel="noreferrer external nofollow"
              >
                <span className="icon-facebook"></span>
              </a>
              <a
                href={instagram}
                target="_blank"
                rel="noreferrer external nofollow"
              >
                <span className="icon-instagram"></span>
              </a>
            </div>
          </div>
        </div>
        <div className={st.tiki}>
          <a
            href="https://www.tiki.com.br"
            target="_blank"
            rel="noreferrer external nofollow"
          >
            Tiki
          </a>
        </div>
      </div>
    </footer>
  )
}
