import React from 'react'
import PropTypes from 'prop-types'

import * as st from '~/assets/styl/Banner.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function Banner(props) {
  const { image, title, buttonText, anchor, maxWidth } = props
  return (
    <section
      className={st.banner}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <h1 style={{ maxWidth: maxWidth || '100%' }}>{title}</h1>
      {buttonText && <AnchorLink to={anchor}>{buttonText}</AnchorLink>}
    </section>
  )
}

Banner.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  anchor: PropTypes.string,
  maxWidth: PropTypes.number,
}
