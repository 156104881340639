// extracted by mini-css-extract-plugin
export var banner = "Banner-module--banner--NXeuY";
export var gatsbyFocusWrapper = "Banner-module--gatsby-focus-wrapper--rcL0E";
export var hideOnDesktop = "Banner-module--hide-on-desktop--TnT37";
export var hideOnMobile = "Banner-module--hide-on-mobile--rPLY8";
export var iconCar = "Banner-module--icon-car--Ho4Hr";
export var iconCars = "Banner-module--icon-cars--r9gus";
export var iconClock = "Banner-module--icon-clock--VLxDi";
export var iconEletricCar = "Banner-module--icon-eletric-car--PjG9j";
export var iconFacebook = "Banner-module--icon-facebook--BDlyW";
export var iconGear = "Banner-module--icon-gear--FpoDU";
export var iconInstagram = "Banner-module--icon-instagram--xXpKf";
export var iconKm = "Banner-module--icon-km--G1bLX";
export var iconPhone = "Banner-module--icon-phone--5HPJX";
export var iconRight = "Banner-module--icon-right--shtjK";
export var iconWhatsapp = "Banner-module--icon-whatsapp--1IR5d";
export var pageWidth = "Banner-module--page-width--WfK1n";
export var path1 = "Banner-module--path1--+2VyG";
export var path2 = "Banner-module--path2--v-OM+";
export var path3 = "Banner-module--path3--3GpjV";
export var view = "Banner-module--view--1Jl1V";
export var views = "Banner-module--views--CVzPR";